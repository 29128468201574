<template>
    <div>
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <div class="nav">
            <span
                style="margin-left:73px;"
                @click="navv(1)"
                :class="na == 1 ? 'na1' : ''"
            >
                <b>|</b> 自然环境</span
            >
            <!-- 本体病害 临时隐藏 -->
            <!-- <span @click="navv(2)" :class="na == 2 ? 'na1' : ''">
                <b>|</b> 本体病害</span
            > -->
        </div>
        <button
            style=" margin-left:98px;"
            :class="btna"
            @click="sub()"
            v-if="na == 1"
        >
            水质
        </button>
        <!-- 风速 临时隐藏 -->
        <!-- <button :class="btna2" @click="sub2()" v-if="na == 1">风速</button> -->
        <button :class="btna3" @click="sub3()" v-if="na == 1">土壤墒情</button>
        <button :class="btna6" @click="sub6()" v-if="na == 1">气象</button>
        <button :class="btna5" @click="sub5()" v-if="na == 1">大气质量</button>
        <button :class="btna4" @click="sub4()" v-if="na == 1">微环境气象</button>
        <button :class="btna11" @click="sub11()" v-if="na == 1">气象灾害</button>
        <button :class="btna12" @click="sub12()" v-if="na == 1">微环境大气质量</button>

        <div style=" margin-left:98px;" v-if="na == 2">
            <button
                v-for="item in diseaseList"
                :key="item.bm"
                :class="selectDisease == item.bm ? 'btna' : 'btna2'"
                @click="changeDisease(item.bm)"
            >
                {{ item.label }}
            </button>
        </div>
        <!-- <button :class="btna9" @click="sub9()" v-if="na == 2">位移</button> -->

        <router-view class="viee"></router-view>
    </div>
</template>
<script>
export default {
    name: "equipmentdata_management",
    components: {},
    data() {
        return {
            itemName: "数据管理",
            breadlist: [
                {
                    path: "",
                    name: "数据管理与接入",
                },
            ],
            btna: "btna2",
            btna2: "btna2",
            btna3: "btna2",
            btna4: "btna2",
            btna5: "btna2",
            btna6: "btna2",
            btna7: "btna2",
            btna9: "btna2",
            btna11: "btna2",
            btna12: "btna2",
            reveal: true,
            reveal2: false,
            na: 1,
            diseaseList: [
                {
                    label: "裂缝",
                    bm: 60,
                },
                {
                    label: "构件缺失",
                    bm: 17,
                },
                {
                    label: "构件变形",
                    bm: 11,
                },
                {
                    label: "糟朽",
                    bm: 4,
                },
                {
                    label: "构件松动",
                    bm: 240,
                },
                {
                    label: "不均匀沉降",
                    bm: 0,
                },
                {
                    label: "植物病害",
                    bm: 6,
                },
                {
                    label: "生物病害",
                    bm: 3,
                }
            ],
            diseaseList1: [
                {
                    label: "裂缝",
                    bm: 7050401,
                },
                {
                    label: "倾斜",
                    bm: 7050402,
                },
                {
                    label: "风化",
                    bm: 7050408,
                },
                {
                    label: "臌胀",
                    bm: 7050403,
                },
                {
                    label: "沉降",
                    bm: 7050404,
                },
                {
                    label: "位移",
                    bm: 7050405,
                },
                {
                    label: "应变",
                    bm: 7050406,
                },
            ],
            selectDisease: "",
        };
    },
    methods: {
        sub() {
            this.btna = "btna";
            this.btna2 = "btna2";
            this.btna3 = "btna2";
            this.btna4 = "btna2";
            this.btna5 = "btna2";
            this.btna6 = "btna2";
            this.btna11 = "btna2";
            this.btna12 = "btna2";
            this.$router.push({
                name: "equipmentdata_waterquality_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub2() {
            this.btna2 = "btna";
            this.btna = "btna2";
            this.btna3 = "btna2";
            this.btna4 = "btna2";
            this.btna5 = "btna2";
            this.btna6 = "btna2";
            this.btna11 = "btna2";
            this.btna12 = "btna2";
            this.$router.push({
                name: "equipmentdata_windspeed_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub3() {
            this.btna3 = "btna";
            this.btna = "btna2";
            this.btna2 = "btna2";
            this.btna4 = "btna2";
            this.btna5 = "btna2";
            this.btna6 = "btna2";
            this.btna11 = "btna2";
            this.btna12 = "btna2";
            this.$router.push({
                name: "equipmentdata_soilmoisture_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub4() {
            this.btna4 = "btna";
            this.btna = "btna2";
            this.btna2 = "btna2";
            this.btna3 = "btna2";
            this.btna5 = "btna2";
            this.btna6 = "btna2";
            this.btna11 = "btna2";
            this.btna12 = "btna2";
            this.$router.push({
                name: "equipmentdata_microenvironment_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub5() {
            this.btna5 = "btna";
            this.btna = "btna2";
            this.btna2 = "btna2";
            this.btna3 = "btna2";
            this.btna4 = "btna2";
            this.btna6 = "btna2";
            this.btna11 = "btna2";
            this.btna12 = "btna2";
            this.$router.push({
                name: "equipmentdata_airquality_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub6() {
            this.btna6 = "btna";
            this.btna = "btna2";
            this.btna2 = "btna2";
            this.btna3 = "btna2";
            this.btna4 = "btna2";
            this.btna5 = "btna2";
            this.btna11 = "btna2";
            this.btna12 = "btna2";
            this.$router.push({
                name: "equipmentdata_meteorological_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub11() {
            this.btna11 = "btna";
            this.btna = "btna2";
            this.btna2 = "btna2";
            this.btna3 = "btna2";
            this.btna4 = "btna2";
            this.btna5 = "btna2";
            this.btna6 = "btna2";
            this.btna12 = "btna2";
            this.$router.push({
                name: "equipmentdata_meteorologicaldisaster_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub12() {
            this.btna12 = "btna";
            this.btna = "btna2";
            this.btna2 = "btna2";
            this.btna3 = "btna2";
            this.btna4 = "btna2";
            this.btna5 = "btna2";
            this.btna6 = "btna2";
            this.btna11 = "btna2";
            this.$router.push({
                name: "equipmentdata_micro_airquality_grid",
                query: { state: "collectdataEditable" },
            });
        },
        sub7() {
            // this.btna7 = "btna";
            // this.btna9 = "btna2";
            // this.$router.push({
            //     name: "equipmentdata_crack_grid",
            //     query: { state: "collectdataEditable" },
            // });
        },
        sub9() {
            // this.btna9 = "btna";
            // this.btna7 = "btna2";
            // this.$router.push({
            //     name: "equipmentdata_tilt_grid",
            //     query: { state: "collectdataEditable" },
            // });
        },

        navv(num) {
            this.na = num;
            num == 1 ? this.sub() : num == 2 ? this.changeDisease(this.diseaseList[0].bm) : "";
        },
        changeDisease(key) {
            this.selectDisease = key;
            console.log(key, "121312");
            this.$router.push({
                name: "disease_data",
                query: {
                    // TODO
                    bm: key,
                },
            });
        },
    },
    mounted() {
        this.$route.name == "equipmentdata_waterquality_grid" ||
        this.$route.name == "equipmentdata_windspeed_grid" ||
        this.$route.name == "equipmentdata_soilmoisture_grid" ||
        this.$route.name == "equipmentdata_microenvironment_grid" ||
        this.$route.name == "equipmentdata_airquality_grid" ||
        this.$route.name == "equipmentdata_meteorological_grid" ||
        this.$route.name == "equipmentdata_meteorologicaldisaster_grid" ||
        this.$route.name == "equipmentdata_micro_airquality_grid"
            ? this.navv(1)
            : this.navv(2);
    },
};
</script>
<style>
.viee {
    width: 100%;
    height: calc(100% - 195px);
    margin-top: 20px;
}

.btna {
    background-color: #fff;
    min-width: 90px;
    height: 40px;
    border: none;
    padding: 0 20px;
    cursor: pointer;
    color: #fff;
    background-color: #1862ad;
}

.btna2 {
    background-color: #fff;
    min-width: 90px;
    height: 40px;
    border: none;
    padding: 0 20px;
    cursor: pointer;
    color: rgb(119, 119, 120);
}

.viee .src-css-monitorData-navigation-navigation-6G4D {
    opacity: 0;
    width: 0;
    height: 0;
}

.nav span {
    width: 150px;
    height: 70px;
    font-size: 22px;
    line-height: 70px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    color: rgb(119, 119, 120);
}

.nav span b {
    margin: 0 8px;
}

.nav .na1 {
    color: #1862ad;
}
</style>
